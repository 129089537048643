import React from 'react';
import "./styles.css";
import em_construcao from '../../assets/image/em_construcao.jpg'

export const NotFoundPage = () => {

  return (
      <div>
        <img src={em_construcao} alt="Minha imagem" />
        
      </div>
  );
};