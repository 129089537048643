import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../service/firebaseConfig';
import { AuthContext } from '../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import Logo from '../../assets/image/logo2.png';
import { ControleInterno } from './Gerente/ControleInterno';
import { Moradores } from './Gerente/Moradores';
import { Porteiro } from './Gerente/Porteiro';
import './styles.css';

export const MenuGerente = () => {
  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpjGerente : null;
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [camposObrigatorios, setCamposObrigatorios] = useState([]);
  const [entradaAutomatica, setEntradaAutomatica] = useState(false);
  const [entradaPorteiro, setEntradaPorteiro] = useState(false);
  const [empresaOk, setEmpresaOk] = useState(false);
  const [tipoEmpresa, setTipoEmpresa] = useState('');
  const url = `https://checkinfacil.com.br/qrcode/:${cnpj}`;
  const title = 'Check-in Fácil';
  const qrCodeRef = useRef(null);

  const navigate = useNavigate(); // Initialize useNavigate hook

  useEffect(() => {
    async function buscaDados() {
      await buscaDadosEmpresa();
    }
    buscaDados();
  }, []);

  async function buscaDadosEmpresa() {
    try {
      const docRef = doc(db, 'contratantes', cnpj);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNomeEmpresa(docSnap.data().nome);
        setCamposObrigatorios(docSnap.data().camposObrigatorios);
        setEmpresaOk(true);
        setEntradaAutomatica(docSnap.data().entradaAutomatica);
        setEntradaPorteiro(docSnap.data().entradaPorteiro);
        setTipoEmpresa(docSnap.data().tipoEmpresa);
      } else {
        toast.error('Empresa não cadastrada no Check-in Fácil.');
      }
    } catch (error) {
      toast.error('Erro ao buscar Empresa.');
    }
  }

  const handleDownload = async () => {
    if (!qrCodeRef.current) return;
  
    const scale = 2; // Define a escala para aumentar a qualidade da imagem
    const canvas = await html2canvas(qrCodeRef.current, {
      scale: scale, // Ajusta a escala para manter a qualidade na tela
      useCORS: true, // Habilita CORS caso haja recursos externos, como imagens
    });
  
    const imageUrl = canvas.toDataURL('image/png');
  
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'qrcode.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const titleContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const logoTitleStyle = {
    marginLeft: '10px', // Espaçamento entre o título e a logo
    width: '50px', // Tamanho ajustado da logo ao lado do título
  };

  function relatorios() {
    if (!cnpj) {
      console.error('CNPJ is missing in MenuGerente');
      return;
    }
    console.log('Navigating to Relatorio with CNPJ:', cnpj);
    navigate('/relatorio', { state: { cnpj: cnpj } });
  }

  return (
    <div className="MenuAdmin">
      <button onClick={relatorios} className="button">
        Relatório de Visitas
      </button>
      <h1>Olá {email}</h1>
      {tipoEmpresa === 'Controle interno' ? <ControleInterno /> : null}
      {tipoEmpresa === 'Condominio Empresarial' ? <Moradores /> : null}
      <Porteiro />
      
      <div className='imagemqrcode'>
      <div ref={qrCodeRef} style={{ textAlign: 'center', width: '320px', height: '400px', position: 'relative' }}>
          <div style={titleContainerStyle}>
            <img src={Logo} alt="Logo" style={logoTitleStyle} />
            <h2 style={{ color: '#80d8e4' }}>{title}</h2>
          </div>
          <QRCode value={url} size={300} fgColor="#01505A" bgColor="#fff" />
        </div>
        <br />
      </div>
      <button onClick={handleDownload}>
        Download QR Code
      </button>
    </div>
  );
};