import { useNavigate } from "react-router-dom";
import "./styles.css";
import Fundo from '../../assets/image/fundo.png'

export const Apresentacao = () => {
  const navigate = useNavigate();
  
  function login() {
    navigate('/login');
  }

  return (
    <div className="container">
      {/* Botão de entrar no canto superior direito */}
      <div className="button-container">
        <button className="login-button" onClick={login}>
          Entrar
        </button>
      </div>

      <div className="content">
        <div className="sections">
          <div className="section funcionalidades">
            <h2>Principais funcionalidades:</h2>
            <ul>
              <li><strong>Cadastro de Visitas:</strong> Porteiros, recepcionistas ou moradores podem cadastrar visitantes facilmente.</li>
              <li><strong>Autorização de Entrada:</strong> O morador pode aprovar ou recusar a visita com apenas um clique.</li>
              <li><strong>Controle de acessos:</strong> Histórico completo de visitas, disponível a qualquer momento.</li>
              <li><strong>Confirmação em Tempo Real:</strong> Portaria é notificada automaticamente quando a visita é autorizada.</li>
              <li><strong>Segurança Aprimorada:</strong> Garante que apenas visitantes autorizados tenham acesso.</li>
              <li><strong>Relatórios Detalhados:</strong> Gere relatórios sobre visitas de forma simples e prática.</li>
            </ul>
          </div>

          <div className="section como-funciona">
            <h2>Como funciona?</h2>
            <ol>
              <li><strong>Cadastro da visita:</strong> O morador ou a portaria registra os dados do visitante.</li>
              <li><strong>Solicitação de Autorização:</strong> Solicitação enviada automaticamente para o morador.</li>
              <li><strong>Confirmação de Autorização:</strong> Portaria é notificada e pode liberar a entrada.</li>
              <li><strong>Registro Completo:</strong> Todas as visitas são registradas para futuras consultas.</li>
            </ol>
          </div>

          <div className="section vantagens">
            <h2>Vantagens:</h2>
            <ul>
              <li><strong>Fácil Acesso:</strong> Acesse de qualquer dispositivo conectado à internet.</li>
              <li><strong>Segurança Total:</strong> Garante que apenas visitantes autorizados tenham acesso.</li>
              <li><strong>Agilidade e Eficiência:</strong> Simplifica o processo de autorização e entrada de visitantes.</li>
              <li><strong>Histórico de Visitas:</strong> Consulte o histórico completo de visitas quando necessário.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
