import React from 'react';
export const TipoEmpresaForm = ({ setTipoEmpresa }) => (
  
  <div>
    <label>Tipo de empresa</label>
    <form className='escolha'>
      {['Bar', 'Boate', 'Condominio residencial', 'Condominio Empresarial', 'Hotel', 'Controle interno'].map((tipo) => (
        <div className='teste' key={tipo}>
          <input
            type="radio"
            name="tipoEmpresa"
            value={tipo}
            onClick={(e) => setTipoEmpresa(e.target.value)}
          />
          <label>{tipo}</label>
        </div>
      ))}
    </form>
  </div>
);