import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';

import { Apresentacao } from '../pages/Apresentacao';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import  { NotFoundPage } from '../pages/NotFoundPage'
import { Register } from '../pages/Register';
import { Qrcode } from '../pages/Qrcode';
import { Atualizar } from '../pages/Atualizar';
import { AtualizarSenha } from '../pages/AtualizarSenha';
import { MenuAdmin } from '../pages/Home/MenuAdmin';
import { MenuContratante } from '../pages/Home/MenuContratante';
import { MenuGerente } from '../pages/Home/MenuGerente';
import { MenuMorador } from '../pages/Home/MenuMorador';
import { MenuPorteiro } from '../pages/Home/MenuPorteiro';
import Relatorio from '../pages/Relatórios';


export const AppRoutes = () => {
  const { currentUser } = useAuth(); // Use o contexto de autenticação para obter o usuário atual

  // Função para verificar se o usuário está logado
  const isUserLoggedIn = () => {
    return currentUser !== null; // Você pode ajustar a lógica de verificação de acordo com sua implementação
  };

  return (
      <Routes>
        <Route exact path="/" element={<Apresentacao />} />
        <Route
          exact
          path="/home"
          element={isUserLoggedIn() ? <Home /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/atualizar"
          element={isUserLoggedIn() ? <Atualizar /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/atualizarsenha"
          element={isUserLoggedIn() ? <AtualizarSenha /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/menuadmin"
          element={isUserLoggedIn() ? <MenuAdmin /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/menucontratante"
          element={isUserLoggedIn() ? <MenuContratante /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/menugerente"
          element={isUserLoggedIn() ? <MenuGerente /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/menumorador"
          element={isUserLoggedIn() ? <MenuMorador /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/menuportaria"
          element={isUserLoggedIn() ? <MenuPorteiro /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        <Route
          exact
          path="/relatorio"
          element={isUserLoggedIn() ? <Relatorio /> : <Navigate to="/login" />} // Redireciona para a página de login se o usuário não estiver logado
        />
        
        <Route path="/login" element={isUserLoggedIn() ? <Navigate to="/home"/>  : <Login />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/qrcode/:qrCodeResult" element={<Qrcode />} />
        {/* Adicione outras rotas públicas e privadas aqui */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
  );
}
