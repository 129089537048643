import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
//import { isSupported, initializeAnalytics } from '@firebase/analytics';
//import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCyVGmgl7N7bp_SMXOnPSdnxoZLhTZPuQU",
  authDomain: "checkin-facil.firebaseapp.com",
  projectId: "checkin-facil",
  storageBucket: "checkin-facil.appspot.com",
  messagingSenderId: "892694369362",
  appId: "1:892694369362:web:894350c56ea269ef97fb8b",
  measurementId: "G-51RNM34JDV"
};

const app = initializeApp(firebaseConfig);
/*
isSupported().then((isSupported) => {
  if (isSupported) {
    // Inicializar o Firebase Analytics apenas se for suportado
    const analytics = getAnalytics(app);
  }
})
*/
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
//export const analytics = getAnalytics(app);
//export const provider = new GoogleAuthProvider();
export default app;