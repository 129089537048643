import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { db } from '../../../service/firebaseConfig';

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import { doc, setDoc, collection, deleteDoc,  where, query, onSnapshot, updateDoc } from "firebase/firestore";

import '../styles.css';

export const Pedidos = () => {

    const { currentUser } = useContext(AuthContext);
    const email = currentUser?.email;
    const location = useLocation();
    const cnpj = location.state ? location.state.cnpjPorteiro : null;
    const [entrada, setEntrada] = useState(null);
    const [consumo, setConsumo] = useState(0);
    const [atualizar, setAtualizar] = useState(false);
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
    const localDate = new Date(currentDate - timezoneOffset);
    const horaVisita = currentDate.toLocaleTimeString('pt-BR', { hour12: false });
    const data = localDate.toISOString().slice(0, 10);

    useEffect(() => {
        const q = query(collection(db, 'pedidos'), 
            where('cnpj', '==', cnpj),
            where('autorizado', '==', false),
            where('data', '==', data)
        );
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const pedidos = [];
            
            querySnapshot.forEach((doc) => {
                const pedidoData = doc.data();
                const pedido = {
                    id: doc.id,
                    cel: pedidoData.cel,
                    cep: pedidoData.cep,
                    cidade: pedidoData.cidade,
                    cpf: pedidoData.cpf,
                    dataNascimento: pedidoData.dataNascimento,
                    emailVisita: pedidoData.email,
                    emailMorador: pedidoData.emailMorador,
                    end: pedidoData.end,
                    genero: pedidoData.genero,
                    nome: pedidoData.nome,
                    numDoc: pedidoData.numDoc,
                    placa: pedidoData.placa,
                    profissao: pedidoData.profissao,
                    tipoDoc: pedidoData.tipoDoc,
                    uf: pedidoData.uf
                    // Adicione outras propriedades que deseja armazenar
                };
                pedidos.push(pedido);
                toast.info(`Nova solicitação de check-in de ${pedidoData.email}`);
            });
            setEntrada(pedidos)
        });

        return () => {
            unsubscribe(); // Unsubscribe the listener when component unmounts
        };
    }, [ atualizar]);

    
    

    async function handleAceitar(emailMorador, emailVisita, id) {

            // Referência à coleção 'usuarios' e subcoleção 'visitas' para o usuário com emailMorador
            const moradorRef = doc(db, 'usuarios', emailVisita); // Referência ao documento do morador
            const visitasRef = collection(moradorRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            const contratanteRef = doc(db, 'contratantes', cnpj); // Referência ao documento do morador
            const visitasContratanteRef = collection(contratanteRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            let dados = {
                emailVisita: emailVisita,
                dataVisita: data,
                horaVisita: horaVisita,
                autorizado: true,
                idVisita: id,
                emailPortaria: email,
                emailMorador: emailMorador,
                tipoRefeicao: null,
                consumo: consumo
            }
            try {
                await setDoc(doc(visitasRef ), dados);
                await setDoc(doc(visitasContratanteRef), dados);
                    // Atualizar o campo 'autorizado' para 'true' na coleção de pedidos
                    const pedidoRef = doc(db, 'pedidos', id); // Referência ao documento de pedidos com base no ID
                    await updateDoc(pedidoRef, {
                        autorizado: true
                    });
                setAtualizar(!atualizar)
                toast.success(`Pedido aceito com sucesso para ${emailVisita}`);
            } catch (error) {
                console.error("Erro ao aceitar o pedido:", error);
                toast.error("Erro ao aceitar o pedido.");
            }
        
            // colocar inclusão com a coleção sendo a de usuario e a sub coleção sendo a empresa
            // fica bom para pesquisa por usuario.
            // e incluir tambem numa coleção com a empresa, pois assim fica facil a pesquisa por empresa
    }

    async function handleRecusar(id) {
        try {
            const pedidoRef = doc(db, 'pedidos', id);

            await deleteDoc(pedidoRef);
            toast.success('Pedido recusado.');
        } catch (error) {
            console.error('Erro ao recusar o pedido:', error);
            toast.error('Erro ao recusar o pedido.');
        }
    }
 
  return (
    <div className="home">
        {entrada? (
            <div>
                {entrada.map((pedido, index) => (
                    <div key={index} className='visitas'>
                        <h4>Pedidos de entrada:</h4>
                        {pedido.emailVisita ? (
                            <span>Email Visita: {pedido.emailVisita}</span>
                        ) : null}
                        {pedido.nome ? (
                            <span>Nome: {pedido.nome}</span>
                        ) : null}
                        {pedido.cpf ? (
                            <span>Cpf: {pedido.cpf}</span>
                        ) : null}
                        {pedido.cel ? (
                            <span>Celular: {pedido.cel}</span>
                        ) : null}
                        {pedido.dataNascimento ? (
                            <span>Data de nascimento: {pedido.dataNascimento}</span>
                        ) : null}
                        {pedido.genero ? (
                            <span>Genero: {pedido.genero}</span>
                        ) : null}
                        {pedido.estado ? (
                            <span>Estado: {pedido.estado}</span>
                        ) : null}
                        {pedido.cidade ? (
                            <span>Cidade: {pedido.cidade}</span>
                        ) : null}
                        {pedido.end ? (
                            <span>Complemento: {pedido.end}</span>
                        ) : null}
                        {pedido.cep ? (
                            <span>Cep: {pedido.cep}</span>
                        ) : null}
                        {pedido.profissao ? (
                            <span>Profissão: {pedido.profissao}</span>
                        ) : null}
                        {pedido.tipoDoc === 1 && <span>Identidade: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 2 && <span>CNH: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 3 && <span>Passaporte: {pedido.numDoc}</span>}
                        {pedido.tipoDoc === 4 && <span>Carteira de trabalho: {pedido.numDoc}</span>}
                        {pedido.placa ? (
                            <span>Placa do carro: {pedido.placa}</span>
                        ) : null}
                        
                        {pedido.emailMorador ? (
                            <span>Autorizado por: {pedido.emailMorador}</span>
                        ) : <span>Pedido não autorizado. Entre em contato com o responsavel</span>}
                        {pedido.tipoEmpresa === 'Bar' ?(
                            <div>
                                <input
                                    type="number"
                                    placeholder={`Digite o numero do cartão de consumo`}
                                    value={consumo || ''}
                                    onChange={(e) => setConsumo(e.target.value)}
                                    autoCapitalize="none"
                                />
                            </div>
                        ) : (<div></div>)}
                        <button className='home-button' onClick={(e) => {handleAceitar(pedido.emailMorador, pedido.emailVisita, pedido.id)}}>aceitar</button>
                        <button className='home-button' onClick={(e) => {handleRecusar(pedido.id)}}>recusar</button>
                    </div>
                ))}
            </div>
        ):(null)}
    </div>
  )
};
