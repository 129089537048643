import React from 'react';

export const StateCitySelector = ({
  states, selectedStateNum, handleStateChange,
  cities, selectedCityNum, handleCityChange
}) => (
  <div className="selecao">
    <label>
      Estado:
      <select value={selectedStateNum} onChange={handleStateChange}>
        <option value="">Selecione o estado</option>
        {states.map((state) => (
          <option key={state.id} value={state.id}>
            {state.nome}
          </option>
        ))}
      </select>
    </label>
    {selectedStateNum && (
      <label>
        Município:
        <select value={selectedCityNum} onChange={handleCityChange}>
          <option value="">Selecione o município</option>
          {cities.map((city) => (
            <option key={city.id} value={city.id}>
              {city.nome}
            </option>
          ))}
        </select>
      </label>
    )}
  </div>
);