import React, { useState, useEffect, useContext, useRef } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider  } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTrash, FaPlus } from 'react-icons/fa';
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { doc, setDoc, getDoc, collection, getDocs } from "firebase/firestore";
import Select from 'react-select';

import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';
import Logo from '../../assets/image/logo.png';
//import downloadIcon from './download.png';
//import QRCodeGenerator from './QRCodeGenerator';

import './styles.css';

export const MenuMorador = () => {

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const location = useLocation();
  const [cnpj, setCnpj] = useState('')
  const [gerentesCadastrados, setGerentesCadastrados] = useState('');
  const [cpfGerente, setCpfGerente] = useState('');
  const [emailGerente, setEmailGerente] = useState('');
  const [emailUsu, setEmailUsu] = useState('');
  const [cpf, setCpf] = useState(0);
  const [nomeGerente, setNomeGerente] = useState('');
  const [atualizar, setAtualizar] = useState(false);
  const [adicionar, setAdicionar] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState([]);
  const [selectedCnpj, setSelectedCnpj] = useState(null);


  const [dataVisita, setDataVisita] = useState('');
  const [cel, setCel] = useState('');
  const [selectedUf, setSelectedUf] = useState(null);
  const [selectedCidade, setSelectedCidade] = useState(null);
  const [cep, setCep] = useState('');
  const [end, setEnd] = useState('');
  const [tipoDoc, setTipoDoc] = useState('');
  const [numDoc, setNumDoc] = useState('');
  const [orgao, setOrgao] = useState('');
  const [placa, setPlaca] = useState('');
  const [profissao, setProfissao] = useState('');
  const [genero, setGenero] = useState('');
  const [tipoCadastro, setTipoCadastro] = useState(0);
  const [cadastroValidado, setCadastroValidado] = useState(false);
  const url = `https://checkinfacil.com.br/qrcode/:${cnpj}`;
  const title = 'Check-in Fácil';
  const qrCodeRef = useRef(null);
  const logoStyle = {
    position: 'absolute',
    width: '20%', // Ajuste o tamanho da logo conforme necessário
    top: '40%', // Ajuste a posição vertical da logo conforme necessário
    left: '40%', // Ajuste a posição horizontal da logo conforme necessário
  };
    const [nome, setNome] = useState('');
    const [emailPortaria, setEmailPortaria] = useState([]);
    const [emailVisita, setEmailVisita] = useState([]);
    const [qtdVisitas, setQtdVisitas] = useState(false);
    const [qtdPorteiros, setQtdPorteiros] = useState(false);
    const [porteirosCadastrados, setPorteirosCadastrados] = useState(null);
    const [adicionarPorteiro, setAdicionarPorteiro] = useState(false);
    const [visitasCadastradas, setVisitasCadastradas] = useState(null);
    const [adicionarVisita, setAdicionarVisita] = useState(false);
    const [maisDe1, setMaisDe1] = useState(false);

    const [empresa, setEmpresa] = useState([])
    
    
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [numUnidades, setNumUnidades] = useState('');
    const [documentos, setDocumentos] = useState([]);

    useEffect(()=> {
        async function buscaEmpresas() {
            await buscaDadosEmpresas();
            await buscaDadosVisitas();
        }
        buscaEmpresas();
      
    },[atualizar]);

    

    async function buscaDadosEmpresas(){
        try {
            const colectionRef = collection(db, 'usuarios', email, 'morador');
            const querySnapshot = await getDocs(colectionRef);
            const nomesEmpresas = [];

            const minhasEmpresas = await Promise.all(
                querySnapshot.docs.map(async (doc) => {
                    setCnpj(doc.id)
                    const nomeDocumento = doc.id;
            
                    const nomeEmpresa = await buscaNomeEmpresa(doc.id);
                    setNomeEmpresa(nomeEmpresa)
                    return { value: doc.id, label: nomeEmpresa };
                })
            );
          
            setEmpresa(minhasEmpresas);
            

            if (querySnapshot.size > 1) {
                setMaisDe1(true);
            }else{
                
            }
        } catch (error) {
            console.log("Erro ao buscar dados da empresa:", error);
        }
            
    }

    async function buscaNomeEmpresa(id) {
        const docRef = doc(db, 'contratantes', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data().nome;
        }
        return '';
    }

    useEffect(()=> {
        async function buscaVisitas() {
            await buscaDadosVisitas();
        }
        buscaVisitas();
      
    },[dataVisita, cnpj]);

    async function buscaDadosVisitas(){
        const docVisita =  cnpj + email + dataVisita;
        const docVisitaSemBarras = docVisita.replace(/\//g, '');
        const nomeDaColecao = docVisitaSemBarras
        try {
            const docRef = doc(db, 'visitas', nomeDaColecao);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                const visitasData = docSnap.data();
                
                if (visitasData && visitasData.email) {
                    const visitasArray = Object.values(visitasData.email);
                    setVisitasCadastradas(visitasArray);
                } else {
                    setVisitasCadastradas([]);
                }
            } else {
                setVisitasCadastradas([]);
            }
        } catch (error) {
            console.log("Erro ao buscar dados das visitas:", error);
            setVisitasCadastradas([]);
        }
            
    }

    

    const handleSelectEmpresa = (selectedCnpj) => {
        setSelectedCnpj(selectedCnpj);
        setCnpj(selectedCnpj.value)
    };

    async function handleAdicionarVisita(){
        setAdicionarVisita(true);

    }

    async function removerVisita(index) {
        const visitasAtuais = visitasCadastradas.filter((_, i) => i !== index)
        let visitas = {
            email: visitasAtuais,
            dataVisita: dataVisita,
                cnpj: cnpj,
                emailMorador: email
        }

        const docVisita =  cnpj + email + dataVisita;
        const docVisitaSemBarras = docVisita.replace(/\//g, '');
        const nomeDaColecao = docVisitaSemBarras
        
        try {
            await setDoc(doc(db, "visitas", nomeDaColecao), visitas);
            setAdicionarVisita(false);
            setAtualizar(!atualizar)
            setQtdVisitas(0)
            Toast.success('Visita excluida com sucesso.');
        } catch (error) {
            toast.error('Erro ao excluir Visita.');
        }
    }

    async function incluirVisita() {
        if (emailVisita.every(isValidEmail)) {
            const docVisita =  cnpj + email + dataVisita;
            const docVisitaSemBarras = docVisita.replace(/\//g, '');
            const nomeDaColecao = docVisitaSemBarras

            let visitas 
            if (visitasCadastradas) {
                visitas =  {
                    email: visitasCadastradas.concat(emailVisita),
                    dataVisita: dataVisita,
                    cnpj: cnpj,
                    emailMorador: email
                }
            }else{
                visitas =  {
                    email: emailVisita,
                    dataVisita: dataVisita,
                    emailMorador: email
                }
            }

            try {
                await setDoc(doc(db, "visitas", nomeDaColecao), visitas);
                setAtualizar(!atualizar)
                setEmailVisita([])
                setAdicionarVisita(false)
                setQtdVisitas(0)
                Toast.success('Vistas incluidas com sucesso.');
    
            } catch (error) {
                toast.error('Erro ao incluir visitas.');
            }
        } else {
            // Exibe uma mensagem de erro se algum e-mail for inválido
            toast.error('Pelo menos um e-mail é inválido. Verifique os e-mails digitados.');
        }

    }

    function isValidEmail(email) {
        // Expressão regular para validar o formato do e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }

 
  return (
    <div className="MenuAdmin">
        <h1>Olá {email}</h1>
        {maisDe1? (
            <div>
                <label htmlFor="genero">*Selecione a empresa para cadastrar a visita</label>
                <Select
                    value={selectedCnpj}
                    onChange={handleSelectEmpresa}
                    options={empresa}
                    placeholder="Gênero"
                    className="seu-select"
                />
            </div>
        ): (<div>
            <h4>Morador da empresa {nomeEmpresa}</h4>
        </div>)}
        <h4>Informe a data da Visita</h4>
        <input
            type="date"
            name="dataVisita"
            id="dataVisita"
            placeholder="Informe a data no formato DD/MM/YYYY"
            onChange={(e) => setDataVisita(e.target.value)}
            autocomplete="off"
        />
        <h4>Visitas cadastrados:</h4>

        {visitasCadastradas? (
            <div>
            {visitasCadastradas.map((email, index) => (
                <div key={index} className='home'>
                <span>Email da visita {index + 1}: {email}</span>
                <button onClick={() => removerVisita(index)} className="remover">
                    <FaTrash />
                </button>
                </div>
            )
            )}
            </div>
        ): (<div>
            <h4>Nenhuma vista cadastrada para a data selecionada</h4>
        </div>)}
        <h4>adicionar Visita</h4>
        <button className="adicionar" onClick={() => handleAdicionarVisita()}>
            <FaPlus />
            Adicionar visita
        </button>

        {adicionarVisita ? (
            <div className='cadastro'>
                <label>*Quantidade visita</label>
                <input
                placeholder="digite a quantidade de visitas"
                value={qtdVisitas}
                onChange={(e) => setQtdVisitas(e.target.value)}
                autoCapitalize="none"
                type="number"
                />
                {Array.from({ length: parseInt(qtdVisitas) }).map((_, index) => (
                <div key={index}>
                    <label>*Email da visita {index + 1}</label>
                    <input
                        type="email"
                        placeholder={`Digite o email da visita ${index + 1}`}
                        value={emailVisita[index] || ''}
                        onChange={(e) => {
                            const updatedEmailVisita = [...emailVisita];
                            updatedEmailVisita[index] = e.target.value;
                            setEmailVisita(updatedEmailVisita);
                        }}
                        autoCapitalize="none"
                    />
                </div>
                ))}
                <button className="home-button" onClick={incluirVisita}>
                Incluir Visitas
                </button>
            </div>
        ) : <div></div>}
        
    </div>
  )
};
