import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from '../../service/firebaseConfig';
import { AuthContext } from '../../routes/contexts/AuthContext';

export function AtualizarSenha() {
  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [nome, setNome] = useState("");
  
  const navigate = useNavigate();
  let dadosUsu = {};

  useEffect(() => {
    async function usuario() {
      await buscaDados();
    }
    usuario();
  }, []);

  async function buscaDados(){
    const docRef = doc(db, 'usuarios', email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      dadosUsu = docSnap.data();
      setNome(docSnap.data().nome);
    }
  }

  async function handleAlterar(e) {
    e.preventDefault();
    
    if (!password || !newPassword) {
      toast.error("Preencha todos os campos.");
      return;
    }

    if (newPassword.length < 6) {
      toast.error("A nova senha deve ter pelo menos 6 caracteres.");
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(email, password);
      
      // Reautenticar o usuário antes de permitir a alteração de senha
      await reauthenticateWithCredential(currentUser, credential);
      
      // Atualizar a senha do usuário
      await updatePassword(currentUser, newPassword);

      toast.success("Senha atualizada com sucesso!");
      navigate('/login');
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        toast.error("A senha antiga está incorreta.");
      } else {
        toast.error("Erro ao atualizar a senha. Tente novamente.");
      }
      console.error("Erro ao alterar a senha:", error);
    }
  }

  function handleLogin() {
    navigate('/login');
  }

  return (
    <div className="register">
      <span>Olá {nome}</span>
      <div className="formulario">
        <div className="inputContainer">
          <label htmlFor="password">*Senha Antiga</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="********************"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="newPassword">*Senha nova</label>
          <input
            type="password"
            name="newPassword"
            id="newPassword"
            placeholder="********************"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        
        <button onClick={handleAlterar} className="button">
          Salvar 
        </button>
        <button onClick={handleLogin} className="button">
          Voltar 
        </button>
      </div>
    </div>
  );
}
