import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { db } from '../../../service/firebaseConfig';
import { toZonedTime  } from 'date-fns-tz';

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import { doc, setDoc, getDocs, collection, where, query } from "firebase/firestore";

import '../styles.css';

export const ListarFuncionarios = () => {

    const { currentUser } = useContext(AuthContext);
    const email = currentUser?.email;
    const location = useLocation();
    const cnpj = location.state ? location.state.cnpjPorteiro : null;
    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
    const localDate = new Date(currentDate - timezoneOffset);
    const horaVisita = currentDate.toLocaleTimeString('pt-BR', { hour12: false });
    const data = localDate.toISOString().slice(0, 10);
    const [atualizar, setAtualizar] = useState(false);
    const [funcionariosCadastrados, setFuncionariosCadastrados] = useState([]);
    const [filtroNome, setFiltroNome] = useState('');
    
    const getRefeicaoInicial = () => {
        if (horaVisita >= '10:00:00' && horaVisita <= '14:00:00') {
          return 'Almoço';
        }
        if (horaVisita >= '14:30:00' && horaVisita <= '17:00:00') {
          return 'Lanche';
        }
        if (horaVisita >= '17:01:00' && horaVisita <= '21:00:00') {
          return 'Jantar';
        }
        if (horaVisita >= '21:01:00' && horaVisita <= '23:00:00') {
          return 'Ceia';
        }
        return ''; // Valor padrão caso nenhum intervalo corresponda
    };
    const selectedRefeicao = getRefeicaoInicial();

    useEffect(() => {
        // Função para buscar os dados dos funcionários
        async function buscaFuncionarios() {
            await buscaDadosFuncionarios();
        }

        // Chamada inicial para buscar os funcionários
        buscaFuncionarios();

      
    }, [atualizar, selectedRefeicao]);

    async function buscaDadosFuncionarios(){
        const diaSemanaAtual = obterDiaSemana();
        try {
            const colectionRef = collection(db, 'contratantes', cnpj, 'funcionario');
            const querySnapshot = await getDocs(colectionRef);
            const funcionarios = [];

        await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const funcionarioData = doc.data();
                const estaDeFolgaHoje = funcionarioData.folga && funcionarioData.folga.includes(diaSemanaAtual);
                const refeicaoPermitida = funcionarioData.refeicoes && funcionarioData.refeicoes.includes(selectedRefeicao);
             
                // Extrair os campos email, cpf, nome e folga
                if (!estaDeFolgaHoje && refeicaoPermitida ) {
                    // Extrair os campos email, cpf, nome e folga
                    const funcionario = {
                        email: doc.id, // o ID do documento é o email
                        cpf: funcionarioData.cpf || '', // Pega o campo 'cpf' do documento
                        nome: funcionarioData.nome || '', // Pega o campo 'nome' do documento
                        folga: funcionarioData.folga || [] // Pega o campo 'folga' (array) do documento
                    };

                    const pedidos = await buscaPedido(funcionario.email)
                    const incluir = validaPedido(pedidos, funcionario);
                    // Adiciona o funcionário ao array de funcionários
                    if (incluir) {
                        funcionarios.push(funcionario);
                    }
                }
            })
        );

        funcionarios.sort((a, b) => a.nome.localeCompare(b.nome));
        // Atualiza o estado com o array de funcionários cadastrados
        setFuncionariosCadastrados(funcionarios);
        } catch (error) {
            console.log("Erro ao buscar dados da empresa:", error);
        }
    }

    function validaPedido(pedidos, funcionario) {
    
        // Verifica se existe algum pedido feito pelo funcionário para o mesmo horário
        for (let pedido of pedidos) {
            if (funcionario.email === pedido.email) {
                if (pedido.tipoRefeicao === selectedRefeicao) {
                    return false;
                }
            }
        }
    
        // Se nenhum pedido corresponder às condições, o funcionário pode ser incluído
        return true;
    }

    async function buscaPedido(email){
        try {
            const pedidos = [];
            const q = query(collection(db, 'contratantes', cnpj, 'pedido'), 
                where('emailVisita', '==', email),
                where('dataVisita', '==', data),
                where('tipoRefeicao', '==', selectedRefeicao)
            );

            const querySnapshot = await getDocs(q);
            
        await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                const pedidoData = doc.data();
                    // Extrair os campos email, cpf, nome e folga
                    const pedido = {
                        email: pedidoData.emailVisita, // o ID do documento é o email
                        tipoRefeicao: pedidoData.tipoRefeicao || [] // Pega o campo 'folga' (array) do documento
                    };

                    // Adiciona o funcionário ao array de funcionários
                    pedidos.push(pedido);
            })
                
        );

        return pedidos;

        } catch (error) {
            console.log("Erro ao buscar dados da empresa:", error);
        }
            
    }

    async function handleAceitar(emailMorador, emailVisita, id) {

            // Referência à coleção 'usuarios' e subcoleção 'visitas' para o usuário com emailMorador
            const moradorRef = doc(db, 'usuarios', emailVisita); // Referência ao documento do morador
            const visitasRef = collection(moradorRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            const contratanteRef = doc(db, 'contratantes', cnpj); // Referência ao documento do morador
            const visitasContratanteRef = collection(contratanteRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            let dados = {
                emailVisita: emailVisita,
                dataVisita: data,
                horaVisita: horaVisita,
                autorizado: true,
                idVisita: id,
                emailPortaria: email,
                emailMorador: emailMorador,
                tipoRefeicao: selectedRefeicao
            }
            try {
                await setDoc(doc(visitasRef ), dados);
                await setDoc(doc(visitasContratanteRef), dados);
                setAtualizar(!atualizar)
                toast.success(`Pedido aceito com sucesso para ${emailVisita}`);
            } catch (error) {
                console.log("Erro ao aceitar o pedido:", error);
                toast.error("Erro ao aceitar o pedido.");
            }
            // colocar inclusão com a coleção sendo a de usuario e a sub coleção sendo a empresa
            // fica bom para pesquisa por usuario.
            // e incluir tambem numa coleção com a empresa, pois assim fica facil a pesquisa por empresa
    }

    
    function obterDiaSemana() {
        const timeZone = 'America/Sao_Paulo'; // Define o fuso horário de Brasília
        
        // Converte a data para o fuso horário de Brasília
        const dataBrasilia = toZonedTime(data, timeZone);
        
        const diasDaSemana = [
            'domingo',
            'segunda',
            'terça',
            'quarta',
            'quinta',
            'sexta',
            'sábado'
        ];
        
        const numeroDiaSemana = dataBrasilia.getDay(); // Agora pega o dia no fuso de Brasília
        
        return diasDaSemana[numeroDiaSemana];
    }
    return (
        <div className='autorizar'>
            <div className="filtro">
                <input
                    type="text"
                    placeholder="Filtrar por nome..."
                    value={filtroNome}
                    onChange={(e) => setFiltroNome(e.target.value)}
                    className="filtro-input"
                />
            </div>
            {funcionariosCadastrados.length > 0 ? (
                <div>
                    <h4>Funcionários autorizados:</h4>
                    {funcionariosCadastrados
                        .filter(func => func.nome.toLowerCase().includes(filtroNome.toLowerCase()))
                        .map((funcionario, index) => (
                        <div key={index} className='lista'>
                            <span>{funcionario.nome}</span>
                            <button onClick={(e) => handleAceitar(email, funcionario.email, 'automatico')}>
                                Autorizar refeição
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <h4>Nenhum funcionário autorizado para essa refeição:</h4>
            )}
        </div>
    )
};
