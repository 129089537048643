import React, { useContext, useState, useEffect } from 'react';
import { db, storage, auth } from '../../service/firebaseConfig';
import { doc, getDoc } from "firebase/firestore";
import { ref , getDownloadURL } from "firebase/storage";
import axios from 'axios';

export const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({
    tipoCadastro: 0,
    cadastroValidado: false,
    nome: '',
    cpf: '',
    dataNascimento: '',
    cel: '',
    estado: '',
    cidade: '',
    logradouro: '',
    cep: '',
    end: '',
    tipoDoc: '',
    numDoc: '',
    orgao: '',
    placa: '',
    profissao: '',
    genero: '',
    fotoUrl: '',
    fotoDoc: false,
  });
  const [atualizar, setAtualizar] = useState(false);

  function saveUserDataToLocalStorage(data) {
    localStorage.setItem('userData', JSON.stringify(data));
  }

  function loadUserDataFromLocalStorage() {
    const savedData = localStorage.getItem('userData');
    if (savedData) {
      setUserData(JSON.parse(savedData));
    }
  }

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    localStorage.removeItem('userData');
    return auth.signOut();
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (!user) {
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const buscaDados = async () => {
      try {
        const docRef = doc(db, 'usuarios', currentUser.email);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          saveUserDataToLocalStorage(data);

          if (data.fotoDoc) {
            buscarImagem();
          }
        }
      } catch (error) {
        console.log("Erro ao buscar dados do usuário:", error);
      } finally {
        setLoading(false);
      }
    };

    buscaDados();
  }, [currentUser, atualizar]);

  useEffect(() => {
    loadUserDataFromLocalStorage();
  }, []);

  async function buscarImagem() {
    try {
      const storageRef = ref(storage, `Images/${currentUser.email}/fotodoc`);
      const imageUrl = await getDownloadURL(storageRef);
      setUserData(prevData => ({ ...prevData, fotoUrl: imageUrl }));
      saveUserDataToLocalStorage({ ...userData, fotoUrl: imageUrl });
    } catch (error) {
      console.log("Erro ao buscar imagem.");
    }
  }

  function triggerAtualizacao() {
    setAtualizar(prev => !prev);
  }

  const value = {
    currentUser,
    userData,
    triggerAtualizacao, 
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
