import React from 'react';

export const CheckboxGroup = ({ checkboxes }) => (
  <div className='checkbox'>
    {checkboxes.map((checkbox) => (
      <div className='checkbox-item' key={checkbox.id}>
        
        <input
          type="checkbox"
          id={checkbox.id}
          checked={checkbox.checked}
          onChange={() => checkbox.onChange(!checkbox.checked)}
        />
        <label htmlFor={checkbox.id}>{checkbox.label}</label>
      </div>
    ))}
  </div>
);