import { useState, useRef, useEffect, useContext  } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { IMaskInput } from "react-imask";
import Select from 'react-select';
import axios from 'axios';

import { validar } from '../../components/utils';
import "./styles.css";

import { toast } from "react-toastify";

import { doc, setDoc, getDoc } from "firebase/firestore";
// import { getStorage } from "firebase/storage";
import { ref , getDownloadURL, uploadBytes } from "firebase/storage";
import { db, storage, auth } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';

export function Atualizar() {

  const { currentUser, userData, triggerAtualizacao } = useContext(AuthContext);
    console.log('serData.selectedUf = ', userData.estado)
  const email = currentUser?.email;
  const [nomeNovo, setNomeNovo] = useState(userData.nome);
  const [cpfNovo, setCpfNovo] = useState(userData.cpf);
  const [dataNascimentoNovo, setDataNascimentoNovo] = useState(userData.dataNascimento);
  const [celNovo, setCelNovo] = useState(userData.cel);
  const [selectedUfNovo, setSelectedUfNovo] = useState(userData.estado);
  const [selectedCidadeNovo, setSelectedCidadeNovo] = useState(userData.cidade);
  const [logradouroNovo, setLogradouroNovo ] = useState(userData.logradouro);
  const [cepNovo, setCepNovo] = useState(userData.cep);
  const [endNovo, setEndNovo] = useState(userData.end);
  const [tipoDocNovo, setTipoDocNovo] = useState(userData.tipoDoc);
  const [numDocNovo, setNumDocNovo] = useState(userData.numDoc);
  const [orgaoNovo, setOrgaoNovo] = useState(userData.orgao);
  const [placaNovo, setPlacaNovo] = useState(userData.placa);
  const [profissaoNovo, setProfissaoNovo] = useState(userData.profissao);
  const [generoNovo, setGeneroNovo] = useState(userData.genero);
  const [tipoCadastroNovo, setTipoCadastroNovo] = useState(userData.tipoCadastro);
  const [cadastroValidadoNovo, setCadastroValidadoNovo] = useState(userData.cadastroValidado);
  const [fotoUrlNovo, setFotoUrlNovo] = useState(userData.fotoUrl);
  const [fotoDocNovo, setFotoDocNovo] = useState(userData.fotoDoc);
  const [address, setAddress] = useState('');
  const [loadingCep, setLoadingCep] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(userData.tipoDoc);
  const [selectedGenero, setSelectedGenero] = useState(userData.genero);
  const videoRef = useRef(null);
  const [imageData, setImageData] = useState(null);
  const [preview, setPreview] = useState([]);

  const [foto, setFoto] = useState(userData.fotoUrl);
  const [fotoAtualizada, setFotoAtualizada] = useState(false);

  const navigate = useNavigate();
  let validaErro = false
  let dadosUsu = {}
  let downloadURL

  const tiposDoc = [
    { value: 1, label: 'Identidade' },
    { value: 2, label: 'CNH' },
    { value: 3, label: 'Passaporte' },
    { value: 4, label: 'Carteira de trabalho' },
  ];

  const tiposGenero = [
    { value: 1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
    { value: 3, label: 'Não informar' },
  ];

  useEffect(() => {
    if (userData.fotoUrl) {
      setFotoUrlNovo(userData.fotoUrl);
    }
  }, [userData.fotoUrl]);

  useEffect(()=> {
    if (userData.cep) {
      fetchAddress();
    }
  
  },[]);

  const handleSelectDoc = (selectedDoc) => {
    setSelectedDoc(selectedDoc);
    setTipoDocNovo(selectedDoc.label)
  };

  const handleSelectGenero = (selectedGenero) => {
    setSelectedGenero(selectedGenero);
    setGeneroNovo(selectedGenero.label)
  };

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  async function handleCadastrar(e) {
    e.preventDefault();
    let valido = true
    const dados = {
      nome: nomeNovo,
      cpf: cpfNovo,
      dataNascimento: dataNascimentoNovo,
      cep: cepNovo,
      estado: selectedUfNovo,
      tipoDoc: tipoDocNovo,
      numDoc: numDocNovo,
    }
  
    const isValid = validar(dados);
    if (!isValid) {
      // Buscar o cadastro para o email atual
        valido = false
    }

    if (valido){
      let infoCad
            if (!foto) {
                infoCad = 1
            }else{
                infoCad = 2
                if (fotoAtualizada) {
                  await uploadImagem()
                }
            }

            let cadastro = {
             nome: nomeNovo,
             cpf: cpfNovo,
             dataNascimento: dataNascimentoNovo,
             cel: celNovo,
             tipoCadastro: infoCad,
             estado: selectedUfNovo,
             cidade: selectedCidadeNovo,
             cep: cepNovo,
             logradouro: logradouroNovo,
             end: endNovo,
             tipoDoc: tipoDocNovo,
             numDoc: numDocNovo,
             orgao: orgaoNovo,
             placa: placaNovo,
             profissao: profissaoNovo,
             genero: generoNovo,
             cadastroValidado: false,
             fotoDoc: fotoDocNovo
           } 
           try {
            await setDoc(doc(db, "usuarios", email), cadastro);
            toast.success("Usuário atualizado com sucesso!");
            triggerAtualizacao();
            navigate('/login')
          } catch (error) {
            console.log(error)
            toast.error("Erro ao atualizar o cadastro.");
          }
    }
  }

  async function uploadImagem() {
    try {
        const storageRef = ref(storage, `Images/${email}/fotodoc`);
        const result = await uploadBytes(storageRef, foto);
    } catch (error) {
        toast.error({
            type: 'error',
            text1: error,
            text2: 'Erro ao incluir imagem.',
            });
    }
  }

  
  if (loading) {
    return <p>carregando...</p>;
  }

  const fetchAddress = async () => {
    if (cepNovo.length === 8) {
      try {
        setLoadingCep(true);
        const response = await axios.get(`https://viacep.com.br/ws/${cepNovo}/json/`);
        const { data } = response;

        if (data.uf) {
        setAddress(data.logradouro + ', ' + data.localidade + ' - ' + data.uf);
        setSelectedUfNovo(data.uf)
        setSelectedCidadeNovo(data.localidade)
        setLogradouroNovo(data.logradouro)
        }else{
          setSelectedUfNovo('')
          setSelectedCidadeNovo('')
          setLogradouroNovo('')
          setAddress('CEP não encontrado');
        }
      } catch (error) {
        setAddress('CEP não encontrado');
      } finally {
        setLoadingCep(false);
      }
    } else {
      setAddress('');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);
      setFotoUrlNovo(imageUrl);
      setFotoAtualizada(true);
    } else {
      setFotoUrlNovo('');
      setFotoAtualizada(false);
    }
  };

  function handleLogin() {
    navigate('/login')
  }

  return (
    <div className="register">
      <span>Por favor {dadosUsu.nomeNovo} digite suas informações de cadastro</span>
      <div className="formulario">
        <div className="inputContainer" autocomplete="off">
          <label htmlFor="nome">Nome</label>
          <input
            type="text"
            name="nome"
            id="nome"
            value={nomeNovo}
            placeholder="Digite o seu nome"
            onChange={(e) => setNomeNovo(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cpf">CPF</label>
          <IMaskInput
            id="cpf"
            value={cpfNovo || ""}
            mask="000.000.000-00"
            placeholder="Digite o seu CPF"
            onChange={(e) => setCpfNovo(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="dataNascimento">Data de nascimento</label>
          <input
            type="date"
            name="dataNascimento"
            id="dataNascimento"
            value={dataNascimentoNovo}
            placeholder="Informe a data no formato DD/MM/YYYY"
            onChange={(e) => setDataNascimentoNovo(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cel">Celular</label>
          <IMaskInput
            name="phone"
            id="phone"
            mask="(00) 00000-0000"
            placeholder="(00) 00000-0000"
            value={celNovo || ""}
            onChange={(e) => setCelNovo(e.target.value)}
            autocomplete="off"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="genero">Gênero atual - {userData.genero}</label>
          <Select
            value={selectedGenero}
            onChange={handleSelectGenero}
            options={tiposGenero}
            placeholder="Gênero"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="cep">CEP:</label>
          <input
            type="text"
            value={cepNovo}
            placeholder="Digite o seu CEP"
            onChange={(e) => {setCepNovo(e.target.value);
              fetchAddress();}}
            onBlur={fetchAddress}
            autocomplete="off"
          />
          
        </div>
        {loadingCep ? <p>Buscando endereço...</p> : <p>{address}</p>}
        <div className="inputContainer">
          <label htmlFor="end">Complemento</label>
          <input
            type="text"
            name="end"
            id="end"
            value={endNovo}
            placeholder="Complemento do endereço"
            onChange={(e) => setEndNovo(e.target.value)}
          />
          </div>
        <div className="inputContainer">
          <label htmlFor="tipoDoc">Tipo de documento atual - {userData.tipoDoc}</label>
          <Select
            value={selectedDoc}
            onChange={handleSelectDoc}
            options={tiposDoc}
            placeholder="Tipo de documento"
            className="seu-select"
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="numDoc">Numero do documento</label>
          <input
            type="text"
            name="numDoc"
            id="numDoc"
            value={numDocNovo}
            placeholder="Numero do documento"
            onChange={(e) => setNumDocNovo(e.target.value)}
          />
        </div>
        {userData.tipoDoc === 1 ? (
          <div className="inputContainer">
            <label htmlFor="orgao">Orgão expeditor</label>
            <input
              type="text"
              name="orgao"
              id="orgao"
              value={orgaoNovo}
              placeholder="Orgão expeditor"
              onChange={(e) => setOrgaoNovo(e.target.value)}
            />
          </div>
        ): (
            <div></div>
        )}
        <div className="inputContainer">
        <span>Foto do documento é obrigatoria para alguns estabelecimentos</span>
          <label htmlFor="foto" className="foto">Selecionar Foto do documento</label>
          
          <input
            type="file"
            name="foto"
            id="foto"
            style={{ display: 'none' }}
            accept='image/*'
            capture="camera"
            onChange={handleFileChange}
          />
          {fotoUrlNovo && (
            <img src={fotoUrlNovo} alt="preview" width="200" />
          )}
        </div>
        <div className="inputContainer">
          <label htmlFor="profissao">Profissão</label>
          <input
            type="text"
            name="profissao"
            id="profissao"
            value={profissaoNovo}
            placeholder="Sua profissao"
            onChange={(e) => setProfissaoNovo(e.target.value)}
          />
        </div>
        <div className="inputContainer">
          <label htmlFor="placa">Placa</label>
          <input
            type="text"
            name="placa"
            id="placa"
            value={placaNovo}
            placeholder="Placa do seu carro"
            onChange={(e) => setPlacaNovo(e.target.value)}
          />
        </div>

        <button onClick={handleCadastrar} className="button">
          Salvar 
        </button>
        <button onClick={handleLogin} className="button">
          Voltar 
        </button>
      </div>
    </div>
  );
}