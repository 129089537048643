import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { db } from '../../../service/firebaseConfig';
import { IMaskInput } from "react-imask";

import { AuthContext } from '../../../routes/contexts/AuthContext';
import { toast } from "react-toastify";
import { doc, setDoc, getDoc,collection, updateDoc } from "firebase/firestore";
import Select from 'react-select';

import '../styles.css';

export const EntradaPorteiro = () => {

    const { currentUser } = useContext(AuthContext);
    const email = currentUser?.email;
    const location = useLocation();
    const cnpj = location.state ? location.state.cnpjPorteiro : null;
    const [emailVisita, setEmailVisita] = useState('');
    const [tipoEmpresa, setTipoEmpresa] =useState('');
    const [camposObrigatorios, setCamposObrigatorios] = useState([]);
    const [cpf, setCpf] = useState(0);
    const [atualizar, setAtualizar] = useState(false);

    const currentDate = new Date();
    const timezoneOffset = currentDate.getTimezoneOffset() * 60000; // Offset em milissegundos
    const localDate = new Date(currentDate - timezoneOffset);
    const horaVisita = currentDate.toLocaleTimeString('pt-BR', { hour12: false });
    const data = localDate.toISOString().slice(0, 10);
  

    const [dataNascimento, setDataNascimento] = useState('');
    const [cel, setCel] = useState('');
    const [cep, setCep] = useState('');
    const [end, setEnd] = useState('');
    const [selectedGenero, setSelectedGenero] = useState(null);
    const [selectedRefeicao, setSelectedRefeicao] = useState(null);
    const [numDoc, setNumDoc] = useState('');
    const [consumo, setConsumo] = useState(0);
    const [placa, setPlaca] = useState('');
    const [profissao, setProfissao] = useState('');
    const [genero, setGenero] = useState('');
    const [refeicao, setRefeicao] = useState('');
    const [nome, setNome] = useState('');
    
    const tiposGenero = [
        { value: 1, label: 'Masculino' },
        { value: 2, label: 'Feminino' },
        { value: 3, label: 'Não informar' },
    ];

    const Tiposrefeicao = [
        { value: 1, label: 'Almoço' },
        { value: 2, label: 'Lanche' },
        { value: 3, label: 'Jantar' },
        { value: 4, label: 'Ceia' },
    ];

    let validaErro = false

    useEffect(()=> {
        async function buscaEmpresas() {
            await buscaDadosEmpresas();
        }
        buscaEmpresas();
      
    },[atualizar]);

    async function buscaDadosEmpresas(){
        try {
            const docRef = doc(db, 'contratantes', cnpj);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setCamposObrigatorios(docSnap.data().camposObrigatorios);
                setTipoEmpresa(docSnap.data().tipoEmpresa);
            } else {
                toast.error('Empresa não cadastrada no check-in Facil.');
            }
        } catch (error) {
            console.log("Erro ao buscar dados da empresa:", error);
        }
            
    }
    async function handleAceitar(emailMorador, emailVisita, id) {
        console.log('emailVisita = ', emailVisita)
        if (emailVisita === '') {
            emailVisita = 'indeterminado@visita.com'
        }
        validar(emailVisita)
        if (!validaErro){

            // Referência à coleção 'usuarios' e subcoleção 'visitas' para o usuário com emailMorador
            const moradorRef = doc(db, 'usuarios', emailVisita); // Referência ao documento do morador
            const visitasRef = collection(moradorRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            const contratanteRef = doc(db, 'contratantes', cnpj); // Referência ao documento do morador
            const visitasContratanteRef = collection(contratanteRef, 'pedido'); // Subcoleção 'visitas' dentro de 'usuarios'

            let dados = {
                emailVisita: emailVisita,
                dataVisita: data,
                horaVisita: horaVisita,
                autorizado: true,
                idVisita: id,
                emailPortaria: email,
                emailMorador: emailMorador,
                tipoRefeicao: selectedRefeicao,
                consumo: consumo
            }
            try {
                await setDoc(doc(visitasRef ), dados);
                await setDoc(doc(visitasContratanteRef), dados);
                setAtualizar(!atualizar)
                toast.success(`Pedido aceito com sucesso para ${emailVisita}`);
            } catch (error) {
                console.log("Erro ao aceitar o pedido:", error);
                toast.error("Erro ao aceitar o pedido.");
            }
        }
            // colocar inclusão com a coleção sendo a de usuario e a sub coleção sendo a empresa
            // fica bom para pesquisa por usuario.
            // e incluir tambem numa coleção com a empresa, pois assim fica facil a pesquisa por empresa
    }

    function validar(emailVisita) {
//
// Colocar o valida para todos os campos
        if (!selectedRefeicao) {
            toast.error('Selecione o tipo de refeição.');
            validaErro = true
            return !validaErro
        }
    
        // Se passou pelas validações, pode seguir
        return true;
    }

    const handleSelectGenero = (selectedGenero) => {
        setSelectedGenero(selectedGenero);
        setGenero(selectedGenero.value)
    };

    const handleSelectRefeicao = (selectedRefeicao) => {
        setSelectedRefeicao(selectedRefeicao.label);
        setRefeicao(selectedRefeicao.value)
    };
 
    return (
        <div className='MenuAdmin'>
            {camposObrigatorios? (
                <div>
                    <h1>Informar pedido manual</h1>
                    
                    {camposObrigatorios.email ? (
                        <div className='cadastro'>
                            <input
                                type="email"
                                placeholder={`Digite o email`}
                                value={emailVisita || ''}
                                onChange={(e) => setEmailVisita(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.nome ? (
                        <div>
                            <input
                                type="text"
                                placeholder={`Digite o nome`}
                                value={nome || ''}
                                onChange={(e) => setNome(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.cel ? (
                        <div>
                            <input
                                type="number"
                                placeholder={`Digite o celular`}
                                value={cel || ''}
                                onChange={(e) => setCel(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.cpf ? (
                        <div>
                            <IMaskInput
                                id="cpf"
                                value={cpf || ""}
                                mask="000.000.000-00"
                                placeholder="Digite o seu CPF"
                                onChange={(e) => setCpf(e.target.value)}
                                autocomplete="off"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.cep ? (
                        <div>
                            <input
                                type="number"
                                placeholder={`Digite o cep`}
                                value={cep || ''}
                                onChange={(e) => setCep(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.end ? (
                        <div>
                            <input
                                type="text"
                                placeholder={`Digite o endereço`}
                                value={end || ''}
                                onChange={(e) => setEnd(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.dataNascimento ? (
                        <div>
                            <input
                                type="date"
                                placeholder={`Digite a data de nascimento`}
                                value={dataNascimento || ''}
                                onChange={(e) => setDataNascimento(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.numDoc ? (
                        <div>
                            <input
                                type="number"
                                placeholder={`Digite o numero do documento`}
                                value={numDoc || ''}
                                onChange={(e) => setNumDoc(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.genero ? (
                        <div className='selecao'>
                            <Select
                                value={selectedGenero}
                                onChange={handleSelectGenero}
                                options={tiposGenero}
                                placeholder="Gênero"
                                className="seu-select"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.placa ? (
                        <div>
                            <input
                                type="text"
                                placeholder={`Digite a placa`}
                                value={placa || ''}
                                onChange={(e) => setPlaca(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.profissao ? (
                        <div>
                            <input
                                type="text"
                                placeholder={`Digite a profissao`}
                                value={profissao || ''}
                                onChange={(e) => setProfissao(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ): (<div></div>)}
                    {camposObrigatorios.refeicao ?(
                            <div>
                                <Select
                                    value={selectedRefeicao ? { label: selectedRefeicao, value: refeicao } : null}
                                    onChange={handleSelectRefeicao}
                                    options={Tiposrefeicao}
                                    placeholder="Tipo de refeição"
                                    className="seu-select"
                                />
                            </div>
                    ) : (<div></div>)}
                    {camposObrigatorios.consumo ?(
                        <div>
                            <input
                                type="number"
                                placeholder={`Digite o numero do cartão de consumo`}
                                value={consumo || ''}
                                onChange={(e) => setConsumo(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ) : (<div></div>)}
                    {camposObrigatorios.apartamento ?(
                        <div>
                            <input
                                type="number"
                                placeholder={`Digite o numero do apartamento`}
                                value={consumo || ''}
                                onChange={(e) => setConsumo(e.target.value)}
                                autoCapitalize="none"
                            />
                        </div>
                    ) : (<div></div>)}
                    <button className='home-button' 
                        onClick={(e) => {handleAceitar(email, emailVisita, 'automatico')}}>
                        aceitar
                    </button>
                            
                </div>
            ):(null)}
        </div>
    )
};
