import React, { useState, useEffect } from 'react';
import { IMaskInput } from "react-imask";
import { db } from '../../service/firebaseConfig';
import { toast } from "react-toastify";
import Toast from "../../components/Toast";
import { doc, setDoc, collection } from "firebase/firestore";

import { StateCitySelector } from '../../components/StateCitySelector';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { TipoEmpresaForm } from '../../components/TipoEmpresaForm';
import { AlterarEmpresas } from './Admin/AlterarEmpresas';

import './styles.css';

export const MenuAdmin = () => {
  const [cnpj, setCnpj] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [numUnidades, setNumUnidades] = useState('');
  const [emailResp, setEmailResp] = useState('');
  const [tipoEmpresa, setTipoEmpresa] = useState('');
  const [entradaAutomatica, setEntradaAutomatica] = useState(false);
  const [entradaPorteiro, setEntradaPorteiro] = useState(false);
  const [controleFuncionario, setControleFuncionario] = useState(false);
  const [consumoCheck, setConsumoCheck] = useState(false);
  const [apartamentoCheck, setApartamentoCheck] = useState(false);
  const [refeicaoCheck, setRefeicaoCheck] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateNum, setSelectedStateNum] = useState(0);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityNum, setSelectedCitynum] = useState(0);

  const [celCheck, setCelCheck] = useState(false);
  const [cepCheck, setCepCheck] = useState(false);
  const [cpfCheck, setCpfCheck] = useState(false);
  const [dataNascimentoCheck, setDataNascimentoCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(true);
  const [endCheck, setEndCheck] = useState(false);
  const [fotoCheck, setFotoCheck] = useState(false);
  const [generoCheck, setGeneroCheck] = useState(false);
  const [nomeCheck, setNomeCheck] = useState(false);
  const [numDocCheck, setNumDocCheck] = useState(false);
  const [profissaoCheck, setProfissaoCheck] = useState(false);
  const [placaCheck, setPlacaCheck] = useState(false);

  const checkboxes = [
    { id: 'celCheck', label: 'Celular', checked: celCheck, onChange: setCelCheck },
    { id: 'cepCheck', label: 'Cep', checked: cepCheck, onChange: setCepCheck },
    { id: 'cpfCheck', label: 'CPF', checked: cpfCheck, onChange: setCpfCheck },
    { id: 'dataNascimentoCheck', label: 'Data de nascimento', checked: dataNascimentoCheck, onChange: setDataNascimentoCheck },
    { id: 'endCheck', label: 'Endereço', checked: endCheck, onChange: setEndCheck },
    { id: 'fotoCheck', label: 'Foto', checked: fotoCheck, onChange: setFotoCheck },
    { id: 'generoCheck', label: 'Genero', checked: generoCheck, onChange: setGeneroCheck },
    { id: 'nomeCheck', label: 'Nome', checked: nomeCheck, onChange: setNomeCheck },
    { id: 'numDocCheck', label: 'Numero documento', checked: numDocCheck, onChange: setNumDocCheck },
    { id: 'profissaoCheck', label: 'Profissão', checked: profissaoCheck, onChange: setProfissaoCheck },
    { id: 'placaCheck', label: 'Placa', checked: placaCheck, onChange: setPlacaCheck },
    { id: 'entradaAutomatica', label: 'Entrada Automatica', checked: entradaAutomatica, onChange: setEntradaAutomatica },
    { id: 'entradaPorteiro', label: 'Entrada com porteiro', checked: entradaPorteiro, onChange: setEntradaPorteiro },
    { id: 'controleFuncionario', label: 'Entrada Manual', checked: controleFuncionario, onChange: setControleFuncionario },
    { id: 'refeicaoCheck', label: 'Refeição', checked: refeicaoCheck, onChange: setRefeicaoCheck },
    { id: 'consumoCheck', label: 'Cartão consumo', checked: consumoCheck, onChange: setConsumoCheck },
    { id: 'apartamentoCheck', label: 'Apartamento', checked: apartamentoCheck, onChange: setApartamentoCheck },
  ];

  useEffect(() => {
    fetch("https://servicodados.ibge.gov.br/api/v1/localidades/estados/?orderBy=nome")
      .then((response) => response.json())
      .then((data) => setStates(data));
  }, []);

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    const stateNome = event.target.options[event.target.selectedIndex].text;
    setSelectedState(stateNome);
    setSelectedStateNum(stateId);

    fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios?orderBy=nome`)
      .then((response) => response.json())
      .then((data) => setCities(data));
  };

  const handleCityChange = (event) => {
    const cityId = event.target.value;
    const cityNome = event.target.options[event.target.selectedIndex].text;
    setSelectedCitynum(cityId);
    setSelectedCity(cityNome);
  };

  async function incluirContratante() {
    const numeroscnpj = cnpj.replace(/[^0-9]/g, '');

    try {
      const camposObrigatoriosInc = {
        apartamento: apartamentoCheck,
        cel: celCheck,
        cep: cepCheck,
        consumo: consumoCheck,
        cpf: cpfCheck,
        dataNascimento: dataNascimentoCheck,
        email: emailCheck,
        end: endCheck,
        foto: fotoCheck,
        genero: generoCheck,
        nome: nomeCheck,
        numDoc: numDocCheck,
        profissao: profissaoCheck,
        placa: placaCheck,
        refeicao: refeicaoCheck
      };

      const cadastro = {
        contratante: cnpj,
        nome: nomeFantasia,
        tipoEmpresa,
        emailResp,
        unidade: numUnidades,
        entradaAutomatica,
        entradaPorteiro,
        controleFuncionario,
        estado: selectedState,
        nomeMunicipio: selectedCity,
        codigoMunicipio: parseInt(selectedCityNum),
        ativo: true,
        camposObrigatorios: camposObrigatoriosInc,
      };

      await setDoc(doc(db, "contratantes", numeroscnpj), cadastro);

      await setDoc(doc(collection(db, 'contratantes', numeroscnpj, 'dono'), emailResp), {
        email: emailResp,
      });

      await setDoc(doc(collection(db, 'usuarios', emailResp, 'dono'), numeroscnpj), {
        ativo: true,
      });

      Toast.success('Dono incluído com sucesso.');
      
    } catch (error) {
      console.error('Erro = ', error);
      toast.error('Erro ao incluir dono.');
    }
  }

  return (
    <div className="MenuAdmin">
      <AlterarEmpresas/>
      <h1>Cadastrar empresa</h1>
      <StateCitySelector
        states={states}
        selectedStateNum={selectedStateNum}
        handleStateChange={handleStateChange}
        cities={cities}
        selectedCityNum={selectedCityNum}
        handleCityChange={handleCityChange}
      />
      <label>CNPJ empresa contratante</label>
      <IMaskInput
        mask="00.000.000/0000-00"
        value={cnpj}
        onChange={(e) => setCnpj(e.target.value)}
        placeholder="Digite o CNPJ"
      />
      <label>Nome fantasia</label>
      <input
        placeholder="Digite o nome fantasia"
        value={nomeFantasia}
        onChange={(e) => setNomeFantasia(e.target.value)}
      />
      <label>Quantidade de unidades</label>
      <input
        placeholder="Digite o número de unidades"
        value={numUnidades}
        onChange={(e) => setNumUnidades(parseInt(e.target.value))}
        type="number"
      />
      <label>Email do responsável</label>
      <input
        placeholder="Digite o email"
        value={emailResp}
        onChange={(e) => setEmailResp(e.target.value)}
        type="email"
      />
      <TipoEmpresaForm setTipoEmpresa={setTipoEmpresa} />
      <h4>Dados obrigatórios para entrada</h4>
      <CheckboxGroup checkboxes={checkboxes} />
      <button className="home-button" onClick={incluirContratante}>
        Incluir contratante
      </button>
    </div>
  );
};
