import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from "react-router-dom";
import { db } from '../../service/firebaseConfig';

import { toast } from "react-toastify";
import { doc, getDoc } from "firebase/firestore";

import './styles.css';

import { Pedidos } from './Portaria/Pedidos'
import { ListarFuncionarios } from './Portaria/ListarFuncionarios';
import { EntradaPorteiro } from './Portaria/EntradaPorteiro';

export const MenuPorteiro = () => {
    const location = useLocation();
    const cnpj = location.state ? location.state.cnpjPorteiro : null;
    const [nomeEmpresa, setNomeEmpresa] = useState([]);
    const [entradaPorteiro, setEntradaPorteiro] = useState(false);
    const [controleFuncionario, setControleFuncionario] = useState(false);

    useEffect(()=> {
        async function buscaEmpresas() {
            await buscaDadosEmpresas();
        }
        buscaEmpresas();
      
    },[]);

   

    async function buscaDadosEmpresas(){
        console.log('porteiro ')
        console.log('cnpj = ', cnpj)
        try {
            const docRef = doc(db, 'contratantes', cnpj);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setNomeEmpresa(docSnap.data().nome);
                setEntradaPorteiro(docSnap.data().entradaPorteiro);
                setControleFuncionario(docSnap.data().controleFuncionario);
            } else {
                toast.error('Empresa não cadastrada no check-in Facil.');
            }
        } catch (error) {
            console.log("Erro ao buscar dados da empresa:", error);
        }
            
    }

    
    return (
        <div className="home">
            <h1>{nomeEmpresa}</h1>
            <div className='MenuAdmin'>
                {controleFuncionario ?(
                    <ListarFuncionarios />
                ) : (null)}
            </div>
            {entradaPorteiro? (
                <div>
                < Pedidos />
                <EntradaPorteiro />
                </div>
            ) : (null)}  
        </div>
    )
};
