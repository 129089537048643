// Home.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, getDocs  } from 'firebase/firestore';
import { db } from '../../service/firebaseConfig';

import { AuthContext } from '../../routes/contexts/AuthContext';
import { SelectEmpresa } from '../../components/SelectEmpresa';
import { BotaoNavegacao } from '../../components/BotaoNavegacao';

import './styles.css';

export const Home = () => {
  const navigate = useNavigate();
  const { currentUser, tipoCadastro } = useContext(AuthContext);
  const email = currentUser?.email;

  const [cnpjDono, setCnpjDono] = useState(null);
  const [cnpjPorteiro, setCnpjPorteiro] = useState(null);
  const [cnpjGerente, setCnpjGerente] = useState(null);
  const [cnpjMorador, setCnpjMorador] = useState(null);
  const [morador, setMorador] = useState(false);
  const [gerente, setGerente] = useState(false);
  const [porteiro, setPorteiro] = useState(false);
  const [empresa, setEmpresa] = useState(false);
  const [admin, setAdmin] = useState(false);
  
  const [selectedEmpresa, setSelectedEmpresa] = useState('');
  const [porteiros, setPorteiros] = useState([]);
  const [gerentes, setGerentes] = useState([]);
  const [moradores, setMoradores] = useState([]);
  const [dono, setDono] = useState([]);

  useEffect(() => {
    async function usuario() {
      await buscaDados();
    }
    usuario();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buscaDados() {
    if (!email) return;
    await buscaSubcolecao('morador', setMorador, setCnpjMorador, setMoradores);
    await buscaSubcolecao('gerente', setGerente, setCnpjGerente, setGerentes);
    await buscaSubcolecao('porteiro', setPorteiro, setCnpjPorteiro, setPorteiros);
    await buscaSubcolecao('dono', setEmpresa, setCnpjDono, setDono);
    await buscaAdmin();
  }

  async function buscaSubcolecao(role, setRole, setCnpj, setListaRole) {
    const collectionRef = collection(db, 'usuarios', email, role);
    const querySnapshot = await getDocs(collectionRef);
    let detalheEmpresa;
    const listaRole = [];
  
    if (!querySnapshot.empty) {
      setRole(true);
      if (querySnapshot.size === 1) {
        setCnpj(querySnapshot.docs[0].id);
      } else {
        await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            detalheEmpresa = await buscaDadosEmpresa(doc.id);
            listaRole.push({ cnpj: doc.id, nome: detalheEmpresa.nome, tipoEmpresa: detalheEmpresa.tipoEmpresa });
          })
        );
        setListaRole(listaRole);
      }
    }
  }
  
  async function buscaDadosEmpresa(cnpj) {
    const docRef = doc(db, 'contratantes', cnpj);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return {
        nome: docSnap.data().nome,
        tipoEmpresa: docSnap.data().tipoEmpresa
      };
    }
    return {};
  }

  function handleMoradorChange(event) {
    const cnpjSelecionado = event.target.value;
    setCnpjMorador(cnpjSelecionado);
  }

  function handleDonoChange(event) {
    const cnpjSelecionado = event.target.value;
    setCnpjDono(cnpjSelecionado);
  }

  function handlePorteiroChange(event) {
    const cnpjSelecionado = event.target.value;
    setCnpjPorteiro(cnpjSelecionado);
  }

  function handleGerenteChange(event) {
    const cnpjSelecionado = event.target.value;
    setCnpjGerente(cnpjSelecionado);
  }

  async function buscaAdmin() {
    if (email === 'hesleicosta@gmail.com') {
      setAdmin(true);
    }
  }

  function handleAtualizarCadastro() {
    navigate('/atualizar');
  }

  function handleMenuAdmin() {
    navigate('/menuadmin');
  }

  function handleMenuContratante() {
    navigate('/menucontratante', { state: { cnpjDono } });
  }

  function handleMenuGerente() {
    navigate('/menugerente', { state: { cnpjGerente } });
  }

  function handleMenuMorador() {
    navigate('/menumorador', { state: { cnpjMorador } });
  }

  function handleMenuPortaria() {
    navigate('/menuportaria', { state: { cnpjPorteiro } });
  }

  function handleMeusCheckin() {
    navigate('/relatorio', { state: { ownCheckins: true } });
  }

  return (
    <div className="home">
      {tipoCadastro === 0 ? (
        <div>
          <h1>
            Você possui o cadastro básico, esse cadastro dá direito à entrada apenas em alguns estabelecimentos.
          </h1>
          <h1>Inclua mais informações no cadastro para ter acesso a mais estabelecimentos.</h1>
        </div>
      ) : tipoCadastro === 1 ? (
        <div>
          <h1>
            Você possui o cadastro intermediário, esse cadastro dá direito à entrada em vários estabelecimentos, mas não
            todos.
          </h1>
          <h1>Inclua mais informações no cadastro para ter acesso a mais estabelecimentos.</h1>
        </div>
      ) : tipoCadastro === 2 ? (
        <div>
          <h1>Cadastro completo, você pode fazer o check-in em qualquer lugar.</h1>
        </div>
      ) : null}
      <button className="home-button" onClick={handleAtualizarCadastro}>
        Atualizar Cadastro
      </button>
      <button className="home-button" onClick={handleMeusCheckin}>
        Meus checkins
      </button>
      {morador && (
        <div>
          {!cnpjMorador && moradores.length > 1 ? (
            <SelectEmpresa lista={moradores} value={selectedEmpresa} onChange={handleMoradorChange} />
          ) : (
            <BotaoNavegacao onClick={handleMenuMorador} label="Menu Morador" />
          )}
        </div>
      )}
      {porteiro && (
        <div>
          {!cnpjPorteiro && porteiros.length > 1 ? (
            <SelectEmpresa lista={porteiros} value={selectedEmpresa} onChange={handlePorteiroChange} />
          ) : (
            <BotaoNavegacao onClick={handleMenuPortaria} label="Menu Recepção" />
          )}
        </div>
      )}
      {gerente && (
        <div>
          {!cnpjGerente && gerentes.length > 1 ? (
            <SelectEmpresa lista={gerentes} value={selectedEmpresa} onChange={handleGerenteChange} />
          ) : (
            <BotaoNavegacao onClick={handleMenuGerente} label="Menu Gerente" />
          )}
        </div>
      )}
      {empresa && (
        <div>
          {!cnpjDono && dono.length > 1 ? (
            <SelectEmpresa lista={dono} value={selectedEmpresa} onChange={handleDonoChange} />
          ) : (
            <BotaoNavegacao onClick={handleMenuContratante} label="Menu Proprietario" />
          )}
        </div>
      )}
      {admin && (
        <div>
          <button className="home-button" onClick={handleMenuAdmin}>
            Menu Administrador
          </button>
        </div>
      )}
    </div>
  )
};
