import React from 'react';

export const SelectEmpresa = ({ lista, value, onChange }) => (
  <div>
    <h1>Selecione uma empresa:</h1>
    <select className="seu-select" value={value} onChange={onChange}>
      <option value="" disabled>Escolha uma empresa</option>
      {lista.map((empresa) => (
        <option key={empresa.cnpj} value={empresa.cnpj}>{empresa.nome}</option>
      ))}
    </select>
  </div>
);