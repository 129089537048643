import React, { useState, useEffect } from 'react';
import { db } from '../../../service/firebaseConfig';
import { collection, doc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { toast } from "react-toastify";
import Toast from "../../../components/Toast";
import { CheckboxGroup } from '../../../components/CheckboxGroup';
import { TipoEmpresaForm } from '../../../components/TipoEmpresaForm';
import '../styles.css';

export const AlterarEmpresas = () => {
  const [empresas, setEmpresas] = useState([]);
  const [busca, setBusca] = useState('');
  const [empresaSelecionada, setEmpresaSelecionada] = useState(null);
  const [editando, setEditando] = useState(false);

  // Campos de edição
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [numUnidades, setNumUnidades] = useState('');
  const [emailResp, setEmailResp] = useState('');
  const [tipoEmpresa, setTipoEmpresa] = useState('');

  const [camposObrigatorios, setCamposObrigatorios] = useState({
    cel: false,
    cep: false,
    cpf: false,
    dataNascimento: false,
    email: true,
    end: false,
    foto: false,
    genero: false,
    nome: false,
    numDoc: false,
    profissao: false,
    placa: false,
    refeicao: false
  });

  // Carrega empresas do Firestore
  useEffect(() => {
    const fetchEmpresas = async () => {
      const empresasCollection = collection(db, 'contratantes');
      const empresaSnapshot = await getDocs(empresasCollection);
      const empresaList = empresaSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmpresas(empresaList);
    };

    fetchEmpresas();
  }, []);

  // Filtra empresas com base na busca
  const empresasFiltradas = empresas.filter((empresa) =>
    empresa.nome.toLowerCase().includes(busca.toLowerCase())
  );

  // Seleciona uma empresa para edição
  const selecionarEmpresa = (empresa) => {
    setEmpresaSelecionada(empresa);
    setNomeFantasia(empresa.nome);
    setNumUnidades(empresa.unidade);
    setEmailResp(empresa.emailResp);
    setTipoEmpresa(empresa.tipoEmpresa);
    setCamposObrigatorios(empresa.camposObrigatorios || {}); // Carrega campos obrigatórios
    setEditando(true);
  };

  // Função de atualização no Firestore
  const atualizarEmpresa = async () => {
    if (!empresaSelecionada) return;

    try {
      const empresaRef = doc(db, 'contratantes', empresaSelecionada.id);
      await updateDoc(empresaRef, {
        nome: nomeFantasia,
        unidade: numUnidades,
        emailResp: emailResp,
        tipoEmpresa: tipoEmpresa,
        camposObrigatorios: camposObrigatorios, // Atualiza campos obrigatórios
      });

      Toast.success("Empresa atualizada com sucesso!");
      setEditando(false);
      setEmpresaSelecionada(null);
    } catch (error) {
      console.error("Erro ao atualizar empresa: ", error);
      toast.error("Erro ao atualizar empresa.");
    }
  };

  // Função de exclusão no Firestore
  const excluirEmpresa = async (id) => {
    try {
      await deleteDoc(doc(db, 'contratantes', id));
      setEmpresas(empresas.filter(empresa => empresa.id !== id));
      Toast.success("Empresa excluída com sucesso!");
    } catch (error) {
      console.error("Erro ao excluir empresa: ", error);
      toast.error("Erro ao excluir empresa.");
    }
  };

  // Atualiza o estado dos checkboxes dos campos obrigatórios
  const handleCheckboxChange = (campo) => {
    setCamposObrigatorios((prevState) => ({
      ...prevState,
      [campo]: !prevState[campo],
    }));
  };

  return (
    <div className="MenuAdmin">
      <h2>Gerenciamento de Empresas</h2>

      {/* Campo de busca */}
      <input
        type="text"
        placeholder="Buscar por nome"
        value={busca}
        onChange={(e) => setBusca(e.target.value)}
      />

      {/* Lista de empresas */}
      <ul className="empresa-list">
        {empresasFiltradas.map((empresa) => (
          <li key={empresa.id}>
            <span>{empresa.nome}</span>
            <button onClick={() => selecionarEmpresa(empresa)}>Editar</button>
            <button onClick={() => excluirEmpresa(empresa.id)}>Excluir</button>
          </li>
        ))}
      </ul>

      {/* Formulário de edição */}
      {editando && (
        <div className="edit-form">
          <h3>Editar Empresa</h3>
          <label>Nome Fantasia</label>
          <input
            type="text"
            value={nomeFantasia}
            onChange={(e) => setNomeFantasia(e.target.value)}
          />
          <label>Quantidade de Unidades</label>
          <input
            type="number"
            value={numUnidades}
            onChange={(e) => setNumUnidades(e.target.value)}
          />
          <label>Email do Responsável</label>
          <input
            type="email"
            value={emailResp}
            onChange={(e) => setEmailResp(e.target.value)}
          />
          <TipoEmpresaForm setTipoEmpresa={setTipoEmpresa} />

          {/* Campos obrigatórios */}
          <h4>Campos Obrigatórios</h4>
          {Object.keys(camposObrigatorios).map((campo) => (
            <div key={campo}>
              <input
                type="checkbox"
                id={campo}
                checked={camposObrigatorios[campo]}
                onChange={() => handleCheckboxChange(campo)}
              />
              <label htmlFor={campo}>{campo}</label>
            </div>
          ))}

          <button onClick={atualizarEmpresa}>Salvar</button>
          <button onClick={() => setEditando(false)}>Cancelar</button>
        </div>
      )}
    </div>
  );
};
