import { toast } from "react-toastify";

const Toast = {
  success: (message) => {
    return toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  },
  error: (message) => {
    return toast.error(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  },
  info: (message) => {
    return toast.info(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  },
  warn: (message) => {
    return toast.warn(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  },
};

export default Toast;