import React, { useState, useEffect, useContext, useRef } from 'react';
import './styles.css';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { db } from '../../service/firebaseConfig';
import { AuthContext } from '../../routes/contexts/AuthContext';
import { 
  Paper, 
  Typography, 
  Grid, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Button, 
  Card, 
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
  Divider
} from '@mui/material';
import { 
  FilterList as FilterIcon,
  Sort as SortIcon
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc2626',
    },
  },
});

function Relatorio() {
  const location = useLocation();
  const cnpj = location.state ? location.state.cnpj : null;
  const ownCheckins = location.state?.ownCheckins || false;

  const { currentUser } = useContext(AuthContext);
  const email = currentUser?.email;
  console.log('currentUser', email);

  // State variables
  const [pedidos, setPedidos] = useState([]);
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialDateVal, setInitialDateVal] = useState('');
  const [finalDateVal, setFinalDateVal] = useState('');
  const [tipoRefeicaoVal, setTipoRefeicaoVal] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [nameFilter, setNameFilter] = useState('');  // Add this line with other state variables
  
  useEffect(() => {
    // Automatically fetch data when ownCheckins is true
    if (ownCheckins) {
      fetchPedidos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch and display pedidos
  const fetchPedidos = async () => {
    setLoading(true);
    try {
      let pedidosQuery;
      if (ownCheckins) {
        console.log('Fetching pedidos for user:', email);
        pedidosQuery = collection(db, 'usuarios', email, 'pedido');
      } else {
        console.log('Fetching pedidos for CNPJ:', cnpj || 'default_cnpj');
        pedidosQuery = collection(db, 'contratantes', cnpj || 'default_cnpj', 'pedido');
      }

      const querySnapshot = await getDocs(pedidosQuery);
      let pedidosData = [];

      console.log('Query snapshot size:', querySnapshot.size);

      querySnapshot.forEach((docSnap) => {
        const pedido = { id: docSnap.id, ...docSnap.data() };
        const pedidoDateTime = new Date(`${pedido.dataVisita}T${pedido.horaVisita}`);
        const initialDateTime = initialDateVal ? new Date(`${initialDateVal}T00:00:00`) : null;
        const finalDateTime = finalDateVal ? new Date(`${finalDateVal}T23:59:59`) : null;

        console.log('Processing pedido:', pedido);

        // Apply filters
        if (
          (!initialDateTime || pedidoDateTime >= initialDateTime) &&
          (!finalDateTime || pedidoDateTime <= finalDateTime) &&
          (!tipoRefeicaoVal || pedido.tipoRefeicao === tipoRefeicaoVal) &&
          (!ownCheckins || pedido.emailVisita === email) // Filter for own check-ins
        ) {
          pedidosData.push({ ...pedido, pedidoDateTime });
        }
      });

      console.log('Filtered pedidos data:', pedidosData);

      // Fetch user names for each unique emailVisita
      const uniqueEmails = [...new Set(pedidosData.map(p => p.emailVisita))];
      const namePromises = uniqueEmails.map(async email => {
        if (!email) return { id: null, nome: 'Desconhecido' };
        const funcionarioDoc = await getDoc(doc(db, 'contratantes', cnpj, 'funcionario', email));
        return { id: email, nome: funcionarioDoc.exists() ? funcionarioDoc.data().nome : email };
      });
      const userNames = await Promise.all(namePromises);
      const emailToName = Object.fromEntries(userNames.map(user => [user.id, user.nome || user.id]));

      // Add names to pedidosData
      pedidosData = pedidosData.map(pedido => ({
        ...pedido,
        nome: emailToName[pedido.emailVisita] || pedido.emailVisita
      }));

      // Apply name filter after names are added
      if (nameFilter) {
        pedidosData = pedidosData.filter(pedido => 
          pedido.nome.toLowerCase().includes(nameFilter.toLowerCase())
        );
      }

      // Now calculate countsData based on filtered pedidosData
      const countsData = { 'Almoço': 0, 'Lanche': 0, 'Jantar': 0, 'Ceia': 0 };
      pedidosData.forEach(pedido => {
        if (countsData[pedido.tipoRefeicao] !== undefined) {
          countsData[pedido.tipoRefeicao]++;
        }
      });

      setPedidos(pedidosData);
      setCounts(countsData);
    } catch (error) {
      console.error('Error fetching pedidos:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchPedidos();
  };

  // Handle sorting
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Improved sort function
  const sortedPedidos = React.useMemo(() => {
    if (!sortConfig.key) return pedidos;
    
    return [...pedidos].sort((a, b) => {
      if (!a[sortConfig.key] || !b[sortConfig.key]) return 0;
      
      const aValue = sortConfig.key === 'pedidoDateTime' 
        ? a[sortConfig.key].getTime() 
        : a[sortConfig.key].toString().toLowerCase();
      const bValue = sortConfig.key === 'pedidoDateTime' 
        ? b[sortConfig.key].getTime() 
        : b[sortConfig.key].toString().toLowerCase();

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [pedidos, sortConfig]);

  // Fix table header implementation
  const tableColumns = [
    { key: 'nome', label: 'Nome' },
    { key: 'tipoRefeicao', label: 'Tipo de Refeição' },
    { key: 'pedidoDateTime', label: 'Data e Hora do Pedido' },
    { key: 'emailPortaria', label: 'Email Portaria' },
  ];

  const formatDate = (dateString) => {
    try {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Data inválida';
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Error';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="relatorio">
        <Paper sx={{ p: 3 }}>
          <Box mb={4}>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
              Relatório de Refeições
            </Typography>
            <Typography variant="subtitle1" align="center" color="textSecondary">
              {new Date().toLocaleDateString()}
            </Typography>
          </Box>

          {Object.keys(counts).length > 0 && (
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {Object.entries(counts).map(([meal, count]) => (
                <Grid item xs={12} sm={6} md={3} key={meal}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{meal}</Typography>
                      <Typography variant="h4">{count}</Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {!ownCheckins && (
            <Paper className="no-print" sx={{ p: 3, mb: 4 }}>
              <Grid container spacing={3} component="form" onSubmit={handleSubmit}>
                {/* Add this new Grid item before the other filter fields */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Filtrar por Nome"
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Data inicial"
                    value={initialDateVal}
                    onChange={(e) => setInitialDateVal(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Data final"
                    value={finalDateVal}
                    onChange={(e) => setFinalDateVal(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo de Refeição</InputLabel>
                    <Select
                      value={tipoRefeicaoVal}
                      onChange={(e) => setTipoRefeicaoVal(e.target.value)}
                      label="Tipo de Refeição"
                    >
                      <MenuItem value="" style={{ color: 'black' }}>
                        Todos
                      </MenuItem>
                      <MenuItem value="Almoço" style={{ color: 'black' }}>Almoço</MenuItem>
                      <MenuItem value="Lanche" style={{ color: 'black' }}>Lanche</MenuItem>
                      <MenuItem value="Jantar" style={{ color: 'black' }}>Jantar</MenuItem>
                      <MenuItem value="Ceia" style={{ color: 'black' }}>Ceia</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" gap={2} justifyContent="flex-end">
                    <Button
                      variant="contained"
                      startIcon={<FilterIcon />}
                      type="submit"
                    >
                      Filtrar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" p={4}>
              <CircularProgress />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tableColumns.map((column) => (
                      <TableCell key={column.key}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer'
                          }}
                          onClick={() => handleSort(column.key)}
                        >
                          {column.label}
                          <Tooltip title="Ordenar">
                            <SortIcon
                              sx={{
                                ml: 1,
                                opacity: sortConfig.key === column.key ? 1 : 0.3,
                                transform: sortConfig.direction === 'desc' && sortConfig.key === column.key
                                  ? 'rotate(180deg)'
                                  : 'none',
                                transition: 'transform 0.2s ease-in-out'
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedPedidos.map((pedido) => (
                    <TableRow key={pedido.id}>
                      <TableCell>{pedido.nome}</TableCell>
                      <TableCell>{pedido.tipoRefeicao}</TableCell>
                      <TableCell>{pedido.pedidoDateTime.toLocaleString('pt-BR')}</TableCell>
                      <TableCell>{pedido.emailPortaria || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </div>
    </ThemeProvider>
  );
}

export default Relatorio;
